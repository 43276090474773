'use strict';

import http from '@/utils/http';

const apiList = {
  globalMembership: '/account/operationPand/selectMemberGlobal', // 全球会员分布
  globalIpClub: '/account/operationPand/selectMemberGlobalIp', //全球ip club活动数量分布
  getOrgIds: '/account/operationPand/selectOrgIds', // 查询 OrgIds
  distributedData: '/account/operationPand/selectContactType', // 会员类别分布
  eventsIpClub: '/account/operationPand/getEventsIpClub', // EventsIpClub 数量
  getFileSum: '/account/operationPand/getFileSum', // 参会 数量
  memberSum: '/account/operationPand/getMemberSum', // 会员数量
  keyOperat: '/account/operationPand/getKeyOperat', // 关键运营指标
  scoreTopZero: '/account/operationPand/getScoreTopZero', // 会员积分前10
  areaAvgCshy: '/account/operationPand/getAreaAvgCshy', // 平均成熟度、活跃度
};

export default {
  getMemberDistribution(code, params) {
    return http.get('/account/operationPand/selectContactType', {
      params,
      code,
    });
  },
  getOrgIds(data = {}) {
    return http({
      method: 'GET',
      url: apiList.getOrgIds,
      formData: true,
      data,
    });
  },

  getEventsIpClub(data = {}) {
    return http({
      method: 'GET',
      url: apiList.eventsIpClub,
      formData: true,
      data,
    });
  },

  getFileSum(data = {}) {
    return http({
      method: 'GET',
      url: apiList.getFileSum,
      formData: true,
      data,
    });
  },

  getMemberSum(data = {}) {
    return http({
      method: 'GET',
      url: apiList.memberSum,
      formData: true,
      data,
    });
  },

  getKeyOperat(data = {}) {
    return http({
      method: 'GET',
      url: apiList.keyOperat,
      formData: true,
      data,
    });
  },

  getGlobalMembership(data = {}) {
    return http({
      method: 'GET',
      url: apiList.globalMembership,
      formData: true,
      data,
    });
  },

  getGlobalIpClub(data = {}) {
    return http({
      method: 'GET',
      url: apiList.globalIpClub,
      formData: true,
      data,
    });
  },

  getScoreTopZero(data = {}) {
    return http({
      method: 'GET',
      url: apiList.scoreTopZero,
      formData: true,
      data,
    });
  },

  getAreaAvgCshy(data = {}) {
    return http({
      method: 'GET',
      url: apiList.areaAvgCshy,
      formData: true,
      data,
    });
  },
};
