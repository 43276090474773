<template>
  <a-row :gutter="20" class="mb-20">
    <a-col :span="8">
      <a-card title="任务数量" hoverable>
        <a-row class="ta-center">
          <a-col :span="7">
            <a-statistic
              title="总数"
              :value="48"
              :value-style="{ color: '#5979f8' }"
            />
          </a-col>
          <a-divider type="vertical" class="iss-divider" />
          <a-col :span="7">
            <a-statistic
              title="周更新"
              :value="2"
              :value-style="{ color: '#5979f8' }"
            />
          </a-col>
          <a-divider type="vertical" class="iss-divider" />
          <a-col :span="7">
            <a-statistic
              title="月更新"
              :value="5"
              :value-style="{ color: '#5979f8' }"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card title="会员数量" hoverable>
        <a-row class="ta-center">
          <a-col :span="7">
            <a-statistic
              title="总数"
              :value="14822"
              :value-style="{ color: '#48d7e2' }"
            />
          </a-col>
          <a-divider type="vertical" class="iss-divider" />
          <a-col :span="7">
            <a-statistic
              title="已激活"
              :value="1024"
              :value-style="{ color: '#48d7e2' }"
            />
          </a-col>
          <a-divider type="vertical" class="iss-divider" />
          <a-col :span="7">
            <a-statistic
              title="已注销"
              :value="0"
              :value-style="{ color: '#48d7e2' }"
            />
          </a-col>
        </a-row>
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card title="关键运营指标" hoverable>
        <a-row class="ta-center">
          <a-col :span="11">
            <a-statistic
              title="平均成熟度"
              :value="11.28"
              :precision="2"
              suffix="%"
              :value-style="{ color: '#52c41a' }"
            >
              <template #prefix>
                <arrow-up-outlined />
              </template>
            </a-statistic>
          </a-col>
          <a-divider type="vertical" class="iss-divider" />
          <a-col :span="11">
            <a-statistic title="平均活跃度" :value="0" />
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>
  <a-row :gutter="20" class="mb-20">
    <a-col :span="12">
      <a-card title="活动分布" hoverable :bordered="false">
        <div id="activitySpread" />
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card title="会员分布" hoverable :bordered="false">
        <div id="memberSpread" />
      </a-card>
    </a-col>
  </a-row>
  <a-row :gutter="20" class="mb-20">
    <a-col :span="8">
      <a-card title="会员分布 —— 类别" hoverable :bordered="false">
        <div id="category" />
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card title="会员分布 —— 行业" hoverable :bordered="false">
        <div id="industry" />
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card title="会员分布 —— 职位" hoverable :bordered="false">
        <div id="job" />
      </a-card>
    </a-col>
  </a-row>
  <a-row :gutter="20">
    <a-col :span="8">
      <a-card title="会员积分排行-TOP 10" hoverable :bordered="false">
        <div id="integral" />
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card title="平均成熟度" hoverable :bordered="false">
        <div id="maturity" />
      </a-card>
    </a-col>
    <a-col :span="8">
      <a-card title="平均活跃度" hoverable :bordered="false">
        <div id="activity" />
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { Card, Col, Divider, Row, Statistic } from 'ant-design-vue';
import { ArrowUpOutlined } from '@ant-design/icons-vue';
import DataSet from '@antv/data-set';
import { Chart } from '@antv/g2';
import axios from 'axios';
import dashboardApi from '@/api/dashboard';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ACard: Card,
    AStatistic: Statistic,
    ADivider: Divider,
    ArrowUpOutlined,
  },
  mounted() {
    this.initMap('activitySpread', [{ count: '2000', location: '北京市' }]);
    this.initMap('memberSpread', [
      { count: 2000, location: '湖北省' },
      { count: 1000, location: '广东省' },
    ]);
    // this.initMap('memberSpread', 55, '活跃度');
    // this.ininMemberDistribution();
    this.initPie('category', [
      { count: 298, item: '212大网客户', percent: 0.3 },
      { count: 130, item: '一般NA', percent: 0.21 },
      { count: 12, item: '战略NA', percent: 0.17 },
      { count: 79, item: '战略NC', percent: 0.13 },
      { count: 90, item: '核心NA', percent: 0.09 },
      { count: 256, item: '核心NC', percent: 0.04 },
      { count: 13957, item: '其他', percent: 0.06 },
    ]);
    this.initPie('industry', [
      { count: 130, item: '政府和公共部门', percent: 0.4 },
      { count: 298, item: '能源', percent: 0.23 },
      { count: 12, item: 'ICT服务', percent: 0.17 },
      { count: 79, item: '制造业', percent: 0.06 },
      { count: 90, item: '交通运输', percent: 0.12 },
      { count: 13957, item: '其他', percent: 0.02 },
    ]);
    this.initPie('job', [
      { count: 130, item: '首席执行官', percent: 0.4 },
      { count: 298, item: 'CIO/IT经理', percent: 0.23 },
      { count: 12, item: '工程/技术人员', percent: 0.17 },
      { count: 79, item: '市场总监/经理', percent: 0.06 },
      { count: 90, item: '销售总监/经理', percent: 0.12 },
      { count: 13957, item: '其他', percent: 0.02 },
    ]);
    this.initBar('integral', [
      { country: 'hul**com', population: 6779 },
      { country: 'lih**com', population: 8263 },
      { country: 'che**u', population: 18203 },
      { country: 'hua**an', population: 23459 },
      { country: 'zhu**h', population: 29034 },
      { country: '188**com', population: 53489 },
      { country: '117**com', population: 91744 },
      { country: '664**com', population: 104970 },
      { country: '332**com', population: 106470 },
      { country: '402**com', population: 131744 },
    ]);
    this.initBar('maturity', [
      { country: '东北欧地区部', population: 7 },
      { country: '中东地区部', population: 9 },
      { country: '亚太地区部', population: 6 },
      { country: '北部非洲地区部', population: 6 },
      { country: '南部非洲地区部', population: 8.5 },
      { country: '拉美大区', population: 2.3 },
      { country: '欧亚地区部', population: 4.2 },
      { country: '西欧地区部', population: 1.6 },
      { country: '其他', population: 0.5 },
    ]);
    this.initBar('activity', [
      { country: '东北欧地区部', population: 1234 },
      { country: '中东地区部', population: 45356 },
      { country: '亚太地区部', population: 67854 },
      { country: '北部非洲地区部', population: 57436 },
      { country: '南部非洲地区部', population: 12356 },
      { country: '拉美大区', population: 34353 },
      { country: '欧亚地区部', population: 7954 },
      { country: '西欧地区部', population: 2457 },
      { country: '其他', population: 8654 },
    ]);
  },
  methods: {
    ininMemberDistribution() {
      dashboardApi
        .getMemberDistribution('', {
          dsType: 1,
          column: 'CUSTOMETYPE',
          orgIds: '',
        })
        .then(data => {
          this.initPie('category', data);
        });
    },
    initMap(container, dataset) {
      axios
        .get(
          'https://gw.alipayobjects.com/os/antvdemo/assets/data/china-provinces.geo.json'
        )
        .then(({ data }) => {
          const chart = new Chart({
            container,
            autoFit: true,
            height: 400,
            // padding: [0, 20, 40],
          });
          // force sync scales
          chart.scale({
            x: { sync: true },
            y: { sync: true },
          });
          chart.coordinate('rect').reflect('y');
          chart.legend(false);
          chart.axis(false);

          // style the tooltip
          chart.tooltip({
            showTitle: false,
            showMarkers: false,
            shared: true,
          });
          // data set
          const ds = new DataSet();

          // draw the map
          const dv = ds
            .createView('back')
            .source(data, { type: 'GeoJSON' })
            .transform({
              type: 'geo.projection',
              projection: 'geoMercator',
              as: ['x', 'y', 'centroidX', 'centroidY'],
            });
          const bgView = chart.createView();
          bgView.data(dv.rows);
          bgView.tooltip(false);
          bgView.polygon().position('x*y').style({
            fill: '#DDDDDD',
            stroke: '#b1b1b1',
            lineWidth: 0.5,
            fillOpacity: 0.85,
          });

          // draw the bubble plot
          const userData = ds.createView().source(dataset);
          userData.transform({
            type: 'geo.region',
            field: 'location', // 标注地名的字段
            geoDataView: dv,
            as: ['x', 'y'],
          });
          const pointView = chart.createView();
          pointView.data(userData.rows);
          pointView
            .polygon()
            .position('x*y')
            .color('#faad14')
            .tooltip('location*count')
            .style({
              fillOpacity: 0.45,
            })
            .state({
              active: {
                style: {
                  lineWidth: 1,
                  stroke: '#faad14',
                },
              },
            });
          pointView.interaction('element-active');
          chart.render();
        });
    },
    initPie(container, data) {
      const chart = new Chart({
        container,
        autoFit: true,
        height: 400,
      });
      chart.data(data);
      chart.coordinate('theta', { radius: 0.55 });
      chart.scale('percent', {
        formatter: val => {
          val = val * 100 + '%';
          return val;
        },
      });
      chart.tooltip({ showTitle: false, showMarkers: false });
      chart
        .interval()
        .position('percent')
        .color('item')
        .label('percent', {
          content: data => {
            return `${data.item}\n${data.percent * 100}%`;
          },
        })
        .adjust('stack');
      chart.interaction('element-active');
      chart.interaction('element-single-selected');
      chart.render();
    },
    initBar(container, data) {
      const chart = new Chart({
        container,
        autoFit: true,
        height: 400,
      });
      chart.data(data);
      chart.scale('population', { nice: true });
      chart.coordinate().transpose();
      chart.tooltip({
        showMarkers: false,
      });
      chart.interaction('active-region');
      chart.interval().position('country*population');
      chart.render();
    },
  },
};
</script>

<style lang="less" scoped>
.iss-divider {
  height: 60px;
}
</style>
